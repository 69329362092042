* {
  font-family: Helvetica, Arial, sans-serif;
}

body {
  padding: 60px;
}

.cell {
  border: 2px solid #CCC;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 22pt;
  height: 42px;
  margin: 2.5px;
  text-align: center;
  width: 42px;
}

.incorrect-cell {
  background-color: #555 !important;
  color: white;
  border: 0;
}

.correct-cell {
  background-color: rgb(77, 161, 77) !important;
  color: white;
  border: 0;
}

.misplaced-cell {
  background-color: rgb(185, 175, 77) !important;
  color: white;
  border: 0;
}

.title {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 25pt;
  /* display:flex; */
  /* justify-content: space-between;  */
  /* text-align: center; */
  letter-spacing: 0.2rem;
  /* flex: 1.5; */
  /* flex-grow: 1; */
  /* align-self: center; */
}

.grid-panel {
  /* flex-shrink: 15; */
  flex-grow: 17;
  /* align-self: center; */
  overflow: hidden;
  display: flex;
    /* justify-content: center; */
    align-items: center;
    /* flex-grow: 1; */
}

.keyboard-row {
  margin: 0px !important;
}
.keyboard {
  /* display:flex; */
  /* flex-shrink: 2; */
  /* flex-grow: 1; */
  /* align-self: flex-end; */
}
.container-general {
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  justify-content: space-between;
  /* align-items: stretch; */
}
.key {
  background-color: #DDD;
  border-radius: 5px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 60px;
  margin: 2.5px;
  /* padding: 15px; */
  padding: 20px 0px 0px 0px;
  text-align: center;
  width: 30px;
  /* vertical-align: middle; */
  /* flex: 1;
  display: flex; */
}

.one-and-a-half {
  /* flex: 1.5; */
  font-size: 12px;
  width: 50px;
}

.line {
  display: flex;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}